<template>
  <header class="header">
    <div class="header__inner">
      <router-link
        class="header__logo"
        :to="{ name: 'Home', query: $route.query }"
      >
        <IconLogo />
      </router-link>
      <ChooseRegion />
      <div class="header__right">
        <button @click="openModal" class="header__location">
          <img
            :src="staticUrl(`static/flags/${getLanguage()}.svg`)"
            width="20"
            height="20"
          />
          {{ getLang }}
        </button>
      </div>
    </div>
  </header>
</template>

<script setup>
import IconLogo from "@/components/icons/IconLogo.vue";
import useLanguage from "@/hooks/useLanguage";
import useLanguageModal from "@/hooks/useLanguageModal";
import { staticUrl } from "@/utils";
import { computed } from "vue";
import ChooseRegion from "@/components/ChooseRegion.vue";

const { openModal } = useLanguageModal();
const { getLanguage } = useLanguage();

const getLang = computed(() => {
  const lang = getLanguage();
  if (lang === "ky") return "Тил";
  if (lang === "tg") return "Забон";
  if (lang === "uz") return "Til";
  return "Язык";
});
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  height: var(--heightHeader);
  background: var(--colorBlack);
  display: grid;
  place-items: center;
  padding: 0 var(--widthGutter);
  &__inner {
    width: 100%;
    max-width: var(--widthContainer);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__logo {
    position: relative;
    top: 2px;
    svg {
      @include md {
        width: 56px;
        height: 56px;
      }
    }
  }
  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    margin-left: 8px;
    a {
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      margin-right: 0;
      @include md {
        margin-right: 34px;
      }
    }
  }
  // .header__location
  &__location {
    display: flex;
    align-items: center;
    height: 32px;
    border-radius: 8px;
    padding: 0 12px;
    border: none;
    color: var(--colorBlack);
    background: var(--colorWhite);
    border-radius: 8px;
    font-weight: 800;
    font-family: var(--fontSecondary);
    font-size: var(--sizeButton);
    line-height: var(--lineHeightButton);
    cursor: pointer;
    img {
      margin-right: 8px;
      border-radius: 20px;
      display: block;
      object-fit: cover;
    }
  }
  // .header__location-icon
  &__location-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-left: 2px;
  }
}
</style>
