/**
 * @type {[string]: string[]}
 */
export const cacheTexts = {};

/**
 * @param {string} slug
 * @returns {boolean}
 */
export const hasCache = (slug) => {
  return slug in cacheTexts ? true : false;
};

/**
 * @param {string} slug
 * @param {string[]} texts
 */
export const setCache = (slug, texts) => {
  cacheTexts[slug] = texts;
};

/**
 * @param {string} slug
 * @returns {string[]}
 */
export const getCache = (slug) => {
  return cacheTexts[slug];
};
