<template>
  <div class="region-confirm" id="region-confirm" @click="$emit('close')">
    <div v-show="regionName" class="region-confirm__inner">
      <div class="region-confirm__title">Вы в регионе {{ regionName }}?</div>
      <div class="region-confirm__foot">
        <button
          class="region-confirm__button"
          type="button"
          @click="confirm(region.siteId)"
        >
          Верно
        </button>
        <button
          class="region-confirm__button region-confirm__button_type_secondary"
          type="button"
          @click="this.$emit('changeRegion')"
        >
          Изменить регион
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ModalRegionConfirm",
  emits: ["close", "changeRegion"],
  computed: {
    ...mapState({
      region: (state) => state.api.regionConfirm,
    }),
    regionName() {
      return this.region?.name;
    },
  },
  methods: {
    confirm(id) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          region: id,
        },
      });

      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
.region-confirm {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: grid;
  align-content: start;
  justify-content: start;
  margin: auto;
  @include md {
    // max-width: 1160px;
    padding: 0 calc((100vw - 1040px) / 2);
  }
  &__inner {
    margin: 56px var(--widthGutter);
    border: 2px solid #74c800;
    background-color: #fff;
    box-shadow: 0 8px 30px -4px rgba(0, 0, 0, 0.1),
      0 0 7px 0 rgba(0, 0, 0, 0.06);
    padding: 12px;
    width: 352px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    @include md {
      margin-top: 68px;
    }
  }
  &__title {
  }
  &__foot {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
  &__button {
    border-radius: 8px;
    font-size: 15px;
    gap: 8px;
    height: 30px;
    line-height: 22px;
    padding: 4px 8px;
    background-color: #a7fc00;
    color: #000;
    align-items: center;
    display: flex;
    justify-content: center;
    border: none;
    cursor: pointer;
    outline: none;
    position: relative;
    text-decoration: none;
    font-family: var(--fontPrimary);
    &:hover {
      background-color: #74c800;
    }
    &:active {
      background-color: #b7fb56;
      color: #1e1e1e;
    }
    &_type_secondary {
      background-color: #f2f2f2;
      &:hover {
        background-color: #d9d9d9;
        color: #333;
      }
      &:active {
        background-color: #b3b3b3;
        color: #1e1e1e;
      }
    }
  }
}
</style>
