<template>
  <button
    class="toggle-button"
    :class="{ on: modelValue }"
    @click="toggleButton"
  ></button>
</template>

<script>
export default {
  emits: ["update"],
  data() {
    return {
      modelValue: false,
    };
  },
  methods: {
    toggleButton() {
      this.modelValue = !this.modelValue;
      this.$emit("update", this.modelValue);
    },
  },
};
</script>
<style lang="scss" scoped>
button {
  display: inline-block;
  width: 50px;
  height: 28px;
  background-color: #d0d5da;
  border-radius: 18px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
  border: none;

  &::before {
    content: "";
    position: absolute;
    height: 24px;
    width: 24px;
    border-radius: 100%;
    background: white;
    top: 2px;
    left: 2px;
    transition: left 0.3s;
  }

  &.on {
    background-color: #1f2229;
    &::before {
      left: 24px;
    }
  }
}
</style>
