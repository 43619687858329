import { scroller } from "vue-scrollto/src/scrollTo";

export default {
  data() {
    return {
      scrollTo: scroller(),
    };
  },
  methods: {
    scroll(section) {
      const header = document.getElementsByClassName("header")[0];
      const region = document.getElementsByClassName("region")[0];
      const headerHeight = this.breakpointsDesktop.includes(this.screenSize)
        ? header.clientHeight + 46
        : header.clientHeight;
      const regionHeight = region?.clientHeight || 0;
      const offset =
        this.breakpointsMobile.includes(this.screenSize) &&
        document.body.classList.contains("region-confirm")
          ? headerHeight + regionHeight
          : headerHeight;
      this.scrollTo(section, { offset: -offset });
    },
  },
};
