export default {
  namespaced: true,
  state: {
    promo: {},
  },
  mutations: {
    setPromo(state, value) {
      state.promo = value;
    },
  },
};
