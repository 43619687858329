import useLanguage from "@/hooks/useLanguage";
import { unspanText } from "@/hooks/useTranslate";

const { getLanguage } = useLanguage();

/**
 * Возвращает текст из локального словаря для текущего языка если есть
 * иначе результат перевода с бека
 * @param {string} text
 * @param {import("@/hooks/useLanguage").LanguageCode} lang
 * @returns {string}
 */
const t = (text, lang) => {
  if (lang === "ru") return text;
  return dictionary[text][lang];
};
/**
 * Заменяет текст перевода на текст из локального словаря
 * для самых тяжелых случаев
 * @param {string[]} sourceTexts
 * @param {string[]} targetTexts
 * @returns {string[]}
 */
const fixT = (sourceTexts, targetTexts) => {
  const lang = getLanguage();

  sourceTexts.forEach((text, i) => {
    text = unspanText(text).trim();

    if (text in dictionary && dictionary[text][lang]) {
      targetTexts[i] = t(text, lang);
    }
  });
  return targetTexts;
};

/**
 * Словарик может включать в себя перевод на 1 язык,
 * для других будет использоваться ответ с бека
 * спецсимволы HTML нужно заменить на аналогичные для js (&nbsp будет \xA0)
 */
const dictionary = {
  Вконтакте: {
    ky: "VKontakte",
    tg: "VKontakte",
    uz: "VKontakte",
  },
  Одноклассники: {
    ky: "Одноклассники",
    tg: "",
    uz: "Odnoklassniki",
  },
  "Прозрачные границы M": {
    ky: "АЧЫК ЧЕК АРАЛАР М",
    tg: "",
    uz: "",
  },
  "Прозрачные границы L": {
    ky: "АЧЫК ЧЕК АРАЛАР L",
    tg: "",
    uz: "",
  },
  "каждый 3-й месяц": {
    ky: "Ар бир 3-үнчү ай",
    tg: "",
    uz: "Har 3-inchi oyda",
  },
  "на остальные номера России": {
    ky: "Россиядагы башка номерлерге",
    tg: "",
    uz: "Rossiyadagi boshqa raqamlarga",
  },
  "Смотрите одновременно на 5 устройствах": {
    ky: "Бир эле учурда 5 түзмөктө көрүңүз",
    tg: "",
    uz: "Bir vaqtning o'zida 5 ta qurilmada tomosha qiling",
  },
  "ВЫДЕЛЕННАЯ ЛИНИЯ ДЛЯ ВАШИХ ВОПРОСОВ": {
    ky: "",
    tg: "",
    uz: "SAVOLLARINGIZUCHUN  ALOHIDA  LINIYA AJRATILGAN",
  },
  "Операторы приоритетно принимают звонки по выделенной линии 711": {
    ky: "Операторлор артыкчылыктуу чалууларды атайын 711 линиясы боюнча кабыл алышат",
    tg: "",
    uz: "Operatorlar maxsus 711 liniyasidaustuvor qo'ng'iroqlarni qabul qilishadi",
  },
  "Бесплатная аренда Power Bank в салонах связи t2 и на станциях партнера на 3 дня":
    {
      ky: "Т2 салондорунда жана өнөктөш станцияларда 3 күнгө Power Bank бекер ижарага берилет",
      tg: "",
      uz: "Power Bankni t2 aloqa do'konlarida va hamkor stantsiyalarda 3 kunga bepul ijaraga olish",
    },
  "Пользуйтесь минутами и ГБ в тарифе даже при отрицательном балансе": {
    ky: "Тарифте мүнөттөрдү жана ГБ балансыныз терс учурда да колдонуңуз",
    tg: "",
    uz: "Salbiy qoldiqda ham tarifda daqiqalar va GB-dan foydalaning",
  },
  "Пользуйтесь минутами и ГБ в тарифе даже": {
    ky: "Тарифте мүнөттөрдү жана ГБ балансыныз",
    tg: "",
    uz: "Salbiy qoldiqda ham tarifda daqiqalar",
  },
  "при отрицательном балансе": {
    ky: " терс учурда да колдонуңуз",
    tg: "",
    uz: " va GB-dan foydalaning",
  },
  "Исходящие звонки из популярных стран на номера t2 России включены в тариф": {
    ky: "Тарифке популярдуу өлкөлөрдөн t2 Россияга чыгуучу чалуулар кирет",
    tg: "",
    uz: "Mashhur mamlakatlardan t2 Rossiya raqamlariga chiquvchi qo'ng'iroqlar tarifga kiritilgan",
  },
  "Продавайте и покупайте ГБ, минуты и SMS": {
    ky: "ГБ, мүнөттөрдү жана SMS сатыңыз жана сатып алыңыз",
    tg: "",
    uz: "GB-lar, daqiqalar va SMS-larni sotish va sotib olish",
  },
  "Если закончились деньги на счете – пользуйтесь бесплатно самыми необходимыми приложениями":
    {
      ky: "",
      tg: "",
      uz: "Agar hisobdagi pul tugagan bo'lsa, eng kerakli Ilovalardan  bepul foydalaning",
    },
  "При подключении тарифа цена не изменится": {
    ky: "Тарифте мүнөттөрдү жана ГБ-ды  балансыныз терс учурунда да колдонуңуз",
    tg: "",
    uz: "Tarifga ulanishda narx o’zgarmaydi",
  },
  "Если не пользоваться SIM-картой": {
    ky: "Эгер сиз SIM картаны колдонбосоңуз",
    tg: "",
    uz: "Agar siz SIM kartadan foydalanmasangiz",
  },
  "Минуты не тратятся, а ГБ расходуются из тарифа": {
    ky: "",
    tg: "",
    uz: "Daqiqalar behuda ketmaydi va GB-lar tarifdan sarflanadi",
  },
  "Делитесь интернет-трафиком с другими абонентами t2 по всей стране": {
    ky: "Өлкө боюнча башка t2 абоненттери менен интернет-трафикти бөлүшүңүз",
    tg: "",
    uz: "Internet-trafikni mamlakat bo'ylab boshqa t2 abonentlar bilan baham ko'ring",
  },
  "+60 ГБ каждый 3-й месяц": {
    ky: "Ар бир 3-үнчү ай +60 ГБ",
    tg: "",
    uz: "Har 3-inchi oyda +60 GB",
  },
  "Исходящие звонки на t2 России из популярных стран включены в тариф": {
    ky: "",
    tg: "",
    uz: "Mashhur mamlakatlardan T2 Rossiyaga qo'ng'iroqlar tarifga kiritilgan",
  },
  "Оставшиеся минуты и ГБ не сгорают": {
    ky: "",
    tg: "",
    uz: "Qolgan daqiqalar va GB-lar yonmaydi",
  },
  "Слушайте музыку, смотрите кино, пользуйтесь интернетом": {
    ky: "Бир жазылууда музыка угуңуз, кино көрүңүз, Интернетти жана",
    tg: "",
    uz: "Bitta obunada musiqa tinglang, filmlar tomosha qiling, Internet",
  },
  "и другими сервисами в одной подписке": {
    ky: "башка кызматтарды колдонуңуз",
    tg: "",
    uz: "va boshqa xizmatlardan foydalaning",
  },
  "+35 ГБ каждый 3-й месяц": {
    ky: "Ар бир 3-үнчү ай +35 ГБ",
    tg: "",
    uz: "Har 3-inchi oyda +35 GB",
  },
  "Условия настройки тарифов": {
    ky: "",
    tg: "",
    uz: "Tariflarni belgilash shartlari",
  },
  "Применение рассрочки списания абонентской платы": {
    ky: "",
    tg: "",
    uz: "Abonent to‘lovlarini bo‘lib-bo‘lib to‘lashni qo‘llash",
  },
  "Правила списания абонентской платы и оказания услуг": {
    ky: "",
    tg: "",
    uz: "Abonent to‘lovlarini undirish va xizmatlar ko‘rsatish qoidalari",
  },
  "+10 ГБ каждый 3-й месяц": {
    ky: "Ар бир 3-үнчү ай +10 ГБ",
    tg: "",
    uz: "Har 3-inchi oyda +10 GB",
  },
  "+25 ГБ каждый 3-й месяц": {
    ky: "Ар бир 3-үнчү ай +25 ГБ",
    tg: "",
    uz: "Har 3-inchi oyda +25 GB",
  },
  "ЗАМОРОЗИЛИ ЦЕНУ": {
    ky: "БААНЫ ТОҢДУРУУ",
    tg: "",
    uz: "Tarifga ulanishda narx",
  },
  "ДО КОНЦА 2025 ГОДА": {
    ky: "2025-ЖЫЛДЫН АЯГЫНА ЧЕЙИН",
    tg: "",
    uz: "o’zgarmaydi",
  },
  мес: {
    ky: "ай",
    tg: "",
    uz: "",
  },
  Интернет: {
    ky: "Интернет",
    tg: "",
    uz: "",
  },
  Россия: {
    ky: "Россия",
    tg: "",
    uz: "",
  },
  "Подробная информация на сайте t2.ru": {
    ky: "Толук маалымат t2.ru сайтында",
    tg: "",
    uz: "",
  },
  "Узбекистан – 5 руб./мин.": {
    ky: "Өзбекстан – 5 рубль/мүнөт.",
    tg: "",
    uz: "O'zbekiston - 5 rubl / min.",
  },
  "до 30 мин. в сутки; с 31-й минуты – 6,9 руб./мин.": {
    ky: "30 мүнөткө чейин. күнүнө; 31-мүнөттөн баштап – 6,9 руб/мин.",
    tg: "",
    uz: "30 daqiqagacha. kuniga; 31-daqiqadan boshlab - 6,9 rubl / min.",
  },
  "Таджикистан – 8 руб./мин.": {
    ky: "Тажикстан – 8 рубль/мүнөт.",
    tg: "",
    uz: "Tojikiston - 8 rubl / min.",
  },
  "до 30 мин. в месяц; с 31-й минуты до конца месяца – 20 руб./мин.": {
    ky: "30 мүнөткө чейин. айына; 31-мүнөттөн айдын аягына чейин – 20 руб/мин.",
    tg: "",
    uz: "30 daqiqagacha. oyiga; 31-daqiqadan oyning oxirigacha - 20 rubl / min.",
  },
  "More.tv, тысячи фильмов, сериалов и 100 ТВ-каналов от Wink": {
    ky: "More.tv, Wink-тен 100 ТВ каналдар, миңдеген фильмдер жана сериалдар ",
    tg: "",
    uz: "More.tv, minglab filmlar, seriallar va Wink-dan 100 ta televizion kanallar",
  },
  "3 месяца домашнего интернета бесплатно + 50 ГБ мобильного каждый месяц": {
    ky: "3 ай акысыз үй интернет + ай сайын 50 ГБ мобилдик",
    tg: "",
    uz: "3 oylik uy interneti bepul + har oyda 50 GB mobil interneti",
  },
  "ПРОЗРАЧНЫЕ ГРАНИЦЫ ": {
    ky: "АЧЫК ЧЕК АРАЛАР ",
    tg: "",
    uz: "",
  },
  "МОЙ РАЗГОВОР": {
    ky: "МЕНИН МАЕГИМ",
    tg: "",
    uz: "",
  },
  "Безлимит на t2 России": {
    ky: "Чексиз Россиядагы t2 номерлерге",
    tg: "",
    uz: "",
  },
  Подробнее: {
    ky: "Кененирээк маалымат",
    tg: "",
    uz: "",
  },
};

export { dictionary, fixT, t };
