<template>
  <div class="card" :class="[color]">
    <slot />
  </div>
</template>

<script setup>
defineProps({
  color: {
    type: String,
    required: false,
  },
});
</script>
<script>
export default {
  name: "CardComponent",
};
</script>

<style scoped lang="scss">
.card {
  border-radius: var(--radiusOuter);
  padding: var(--widthPadding);
  &.black {
    background-color: var(--colorBlack);
    color: var(--colorWhite);
  }
  &.grey-dark {
    background-color: #23272f;
  }
  &.grey {
    background-color: var(--colorGrey);
  }
  &.white {
    background-color: var(--colorWhite);
    color: var(--colorBlack);
    box-shadow: 0px -10px 22px rgba(0, 0, 0, 0.03),
      0px 2px 16px rgba(0, 0, 0, 0.06);
  }
  &.pink {
    background-color: var(--colorPink);
    color: var(--colorWhite);
  }
  &.green {
    background-color: var(--colorGreen);
  }
  &.blue {
    background-color: var(--colorBlue);
  }
  &.light-blue {
    background-color: var(--colorLightBlue);
  }
  &.primary {
    background-color: var(--colorPrimaryBg);
    color: var(--colorPrimaryText);
  }
}
</style>
