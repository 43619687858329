export default {
  namespaced: true,
  state: {
    selfReg: {
      title: `SIM-карты t2 с саморегистрацией \nв приложении «Мой t2»`,
    },
  },
  mutations: {
    setEsim(state, value) {
      state.esim = value;
    },
  },
};
