<template>
  <div class="modal">
    <div class="modal__bg" @click="closeModal()"></div>
    <div class="modal__wrap">
      <slot />
    </div>
  </div>
</template>

<script setup>
import useLanguageModal from "@/hooks/useLanguageModal";
import { onMounted, onUnmounted } from "vue";

const { closeModal } = useLanguageModal();

const keyPressEscape = (e) => {
  if (e.key == "Escape") {
    closeModal();
  }
};

onMounted(() => {
  window.addEventListener("keyup", keyPressEscape);
});

onUnmounted(() => {
  window.removeEventListener("keyup", keyPressEscape);
});
</script>

<style lang="scss">
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 1001;
  display: grid;
  align-items: end;
  @include md {
    align-items: center;
  }
  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--colorBlack);
    opacity: 0.4;
  }
  &__wrap {
    position: relative;
    background-color: var(--colorWhite);
    border-radius: var(--radiusInner) var(--radiusInner) 0 0;
    padding: var(--widthPadding);
    margin: 0 auto;
    width: 100%;
    max-width: 752px;
    @include md {
      border-radius: var(--radiusInner);
    }
  }
}
</style>
