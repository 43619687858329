export default {
  namespaced: true,
  state: {
    esim: {},
  },
  mutations: {
    setEsim(state, value) {
      state.esim = value;
    },
  },
};
