import router from "@/router";

export default function useQuery() {
  /**
   * Возвращает квери параметры в виде обьекта
   * @returns {{[string]: string}}
   */
  const getQuery = () => {
    const p = new URLSearchParams(location.search),
      query = {};

    for (const [key, value] of p.entries()) {
      query[key] = value;
    }
    return query;
  };

  /**
   * Получить квери параметр по имени
   * @param {string} name
   * @returns {string | null}
   */
  const getQueryByName = (name) => {
    const query = getQuery();
    if (name in query) return query[name];
    return null;
  };

  /**
   * Задает переданный квери параметр
   * @param {string} query
   */
  const setQuery = (query) => {
    router.push({ query: { ...getQuery(), ...query } });
  };

  return {
    getQueryByName,
    setQuery,
    getQuery,
  };
}
