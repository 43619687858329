<template>
  <div class="tariffs">
    <h3 class="tariffs__title">Тарифы</h3>
    <div class="tariffs__inner">
      <TariffItem
        v-for="(item, index) in filteredTariffs"
        :key="index"
        :item="item"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import TariffItem from "./TariffItem.vue";
// import ToggleButton from "@/components/ToggleButton.vue";

export default {
  components: {
    TariffItem,
  },
  computed: {
    ...mapState({
      tariffs: (state) => state.api.tariffs,
      status: (state) => state.api.tariffsStatus,
    }),
    filteredTariffs() {
      const tariffsToShow = [
        "everywhere-online",
        "black",
        "super-online-plus",
        "my-online-plus",
        "my-online",
        "my-conversation",
        "gaming",
        "premium",
        "prozrachnie-granicy-m",
        "prozrachnie-granicy-l",
      ];
      return this.tariffs
        .filter((t) => tariffsToShow.includes(t.slug))
        .sort((a) => {
          if (a.slug === "everywhere-online") {
            return -1;
          }
          return 0;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.tariffs {
  &__title {
    font-size: var(--sizeHeading3);
    line-height: var(--lineHeightHeading3);
    font-weight: 800;
    text-transform: uppercase;
    padding: var(--widthGapH) 0;
    @include md {
      padding-bottom: 16px;
    }
  }
  &__inner {
    display: grid;
    gap: var(--widthGapV);
    @include md {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
</style>
