export default {
  namespaced: true,
  state: {
    notifications: [],
    hasNewNotifications: false,
  },
  mutations: {
    setNotifications(state, value) {
      state.notifications = value;
    },
    setHasNots(state, value) {
      state.hasNewNotifications = value;
    },
  },
};
