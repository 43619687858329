<template>
  <footer class="footer">
    <div class="footer__inner">
      <p>{{ getText + " " + new Date().getFullYear() }}</p>
      <p>{{ getText2 }}</p>
    </div>
  </footer>
</template>

<script setup>
import useLanguage from "@/hooks/useLanguage";
import { computed } from "vue";

const { getLanguage } = useLanguage();

const getText = computed(() => {
  const lang = getLanguage();
  if (lang === "ky") return "© t2 Орусия,";
  if (lang === "tg") return "© t2 Русия,";
  if (lang === "uz") return "© t2 Rossiya,";
  return "© t2 Россия,";
});
const getText2 = computed(() => {
  const lang = getLanguage();
  if (lang === "ky") return "Толук маалымат сайтта t2.ru";
  if (lang === "tg") return "Маълумоти муфассал дар сайт t2.ru";
  if (lang === "uz") return "Batafsil ma'lumot t2.ru saytida";
  return "Подробная информация на сайте t2.ru";
});
</script>

<style scoped lang="scss">
.footer {
  background: var(--colorBlack);
  display: grid;
  place-items: center;
  color: var(--colorWhite);
  padding: 0 var(--widthGutter);
  text-align: center;
  &__inner {
    width: 100%;
    max-width: var(--widthContainer);
    padding: 32px 0;
    p {
      margin: 0;
      font-size: 10px;
      line-height: 1.2;
      font-weight: 500;
    }
  }
}
</style>
