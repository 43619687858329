<template>
  <div v-if="freeTraffic" class="tariff-features">
    <p v-if="isDetail">
      <template v-for="(item, index) in freeTraffic.icons" :key="index"
        >{{ item.frontNameInternet
        }}<span v-if="index < freeTraffic.icons.length - 1">, </span>
      </template>
    </p>
    <p v-else v-html="freeTraffic.frontName"></p>
    <ul class="tariff-features__pic">
      <template
        v-for="(item, index2) in freeTraffic.icons.slice(0, 7)"
        :key="index2"
      >
        <li>
          <img
            :src="`${item.local ? '' : '//t2.ru'}${item.selectedIcon}`"
            alt=""
            width="32"
            height="32"
          />
        </li>
      </template>
      <li v-if="freeTraffic.icons.length - 7 > 0" class="more">
        +{{ freeTraffic.icons.length - 7 }}
      </li>
    </ul>
  </div>
  <div v-else-if="unlimitedTraffic?.length" class="tariff-features">
    <p>
      <span>
        {{ unlimitedTraffic[0].shortFrontName || "Безлимитный трафик на " }}
      </span>
      <template v-for="(item, index) in unlimitedTraffic" :key="index"
        >{{ item.frontName
        }}<span v-if="index < unlimitedTraffic.length - 1">, </span>
      </template>
    </p>
    <ul class="tariff-features__pic">
      <template
        v-for="(item, index2) in unlimitedTraffic.slice(0, 7)"
        :key="index2"
      >
        <li v-if="item.image">
          <img :src="`//t2.ru${item.image}`" alt="" width="32" height="32" />
        </li>
      </template>
      <li v-if="unlimitedTraffic.length - 7 > 0" class="more">
        +{{ unlimitedTraffic.length - 7 }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
    isDetail: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    unlimitedTraffic() {
      return this.item.parameters
        ?.filter((e) => e.blockAlignment === "unlimitedTraffic")
        .sort((a, b) => b.frontWeight - a.frontWeight);
    },
    freeTraffic() {
      const { containerParams } = this.item;

      if (!containerParams) {
        return null;
      }

      const icons = [
        ...(containerParams.messengers ?? []),
        ...(containerParams.socialNetworks ?? []),
        ...(containerParams.videoServices ?? []),
      ].filter((e) => e.selectedIcon);

      if (!icons.length) {
        return null;
      }

      return {
        frontName: this.item.containerParams?.internetFree || "",
        icons,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.tariff-features {
  margin-top: 16px;
  font-size: 12px;
  line-height: 16px;
  display: grid;
  gap: var(--widthGapV);
  justify-content: space-between;
  @include md {
    margin-top: 24px;
    font-size: var(--sizeText);
    line-height: var(--lineHeightText);
    grid-auto-flow: column;
  }
  ul {
    @include reset-list;
  }
  &__pic {
    display: flex;
    align-items: flex-start;
    li {
      + li {
        margin-left: -8px;
      }
      background-color: var(--colorWhite);
      padding: 2px;
      border-radius: 64px;
      img {
        display: block;
      }
      &.more {
        background-color: #999ea8 !important;
        width: 36px;
        height: 36px;
        color: var(--colorWhite);
        display: grid;
        place-items: center;
        font-size: 12px;
      }
    }
  }
}
</style>
