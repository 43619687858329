import { getCache, hasCache, setCache } from "@/cacheTexts";
import { glossary } from "@/constants";
import { fixT } from "@/dictionary";
import useLanguage from "@/hooks/useLanguage";
import router from "@/router";
import { baseAPI } from "@/store/api";
import { byteLength } from "@/utils";

const excludeTexts = [];

export default function useTranslate() {
  const { getLanguage } = useLanguage();

  /**
   * @param { string[] } texts
   * @returns {Promise<{translations: {text: string}[]}>}
   */
  const fetchTranslate = async (texts) => {
    try {
      const response = await fetch(`${baseAPI}/public/translator/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          format: "HTML",
          texts,
          targetLanguageCode: getLanguage(),
          sourceLanguageCode: "ru",
          glossaryConfig: {
            glossaryData: {
              glossaryPairs: glossary,
            },
          },
        }),
      });
      const j = response.json();
      return j;
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Возвращает массив строк (текстовых узлов) указанного элемента и всех его потомков
   * для фрейма нужно указывать документ фрейма
   * @param { Node } el
   * @param { Document } [doc]
   * @returns { string[] }
   */
  const getTextNodesContent = (el, doc = document) => {
    const children = [];
    const walker = doc.createTreeWalker(el, NodeFilter.SHOW_TEXT, nodeFilter);
    while (walker.nextNode()) {
      children.push(spanText(walker.currentNode.textContent));
    }
    return children;
  };
  /**
   * Устанавливает для указанного элемента текст из переданного массива
   * важно соблюдать порядок строк
   * @param { Node } el
   * @param { Document } [doc]
   * @param { string[] } texts
   */
  const setTextNodesContent = (el, texts, doc = document) => {
    let i = 0;
    const walker = doc.createTreeWalker(el, NodeFilter.SHOW_TEXT, nodeFilter);
    while (walker.nextNode()) {
      walker.currentNode.textContent = unspanText(texts[i]);
      i++;
    }
  };

  /**
   * Переводит текст для указанных селекторов
   * @param {string} selector
   * @param { Document } [doc]
   * @param { string } [cacheName]
   */
  const translate = async (selector, doc = document, cacheName) => {
    const parentNode = doc.querySelector(selector);
    if (!parentNode) throw new Error("Parent node not exist");

    const slug = cacheName ?? router.currentRoute.value.params.slug;

    let texts;

    if (hasCache(slug)) {
      texts = getCache(slug);
    } else {
      texts = getTextNodesContent(parentNode, doc);
      setCache(slug, texts);
    }

    // ру тексты берем из кеша
    if (getLanguage() === "ru") {
      setTextNodesContent(parentNode, texts, doc);
    } else {
      const promises = splitArrayWhile(texts).map((arr) => fetchTranslate(arr));

      let response = await Promise.all(promises);

      response = response
        .map((r) => r.translations)
        .flat(1)
        .map((obj) => obj.text);

      const normalizedTexts = fixT(texts, response);
      console.log("source text length", texts.length);
      console.log(
        "target text length" + " = " + getLanguage(),
        normalizedTexts.length
      );

      setTextNodesContent(parentNode, normalizedTexts, doc);
    }
  };

  /**
   * Возвращает массив массивов поделенный по общей длинне входящих строк
   * @param {string[]} texts
   * @param {number} [maxTextLength]
   * @returns {[string[]]}
   */
  const splitArrayWhile = (texts, maxTextLength = 10e3) => {
    let total = 0,
      tmp = [];
    const result = [];

    for (let i = 0; i < texts.length; i++) {
      total += byteLength(texts[i]);

      if (total <= maxTextLength) {
        tmp.push(texts[i]);
      } else {
        result.push(tmp);
        tmp = [texts[i]];
        total = 1;
      }

      if (texts.length - 1 === i) {
        result.push(tmp);
      }
    }

    return result;
  };

  // /**
  //  * @param {string[]} texts
  //  * @returns {number}
  //  */
  // const getTotalLength = (texts) => {
  //   return texts.reduce((total, t) => (total += t.length), 0);
  // };

  return {
    fetchTranslate,
    getTextNodesContent,
    setTextNodesContent,
    translate,
  };
}

/**
 * Оборачивает латинницу (а также примыкающие пробелы, запятые и цифры) в тег, запрещающий перевод
 * @param {string} str
 * @returns {str}
 */
function spanText(str) {
  return str?.replace(/ ?[A-Z0-9]+,? ?/gi, `<span translate="no">$&</span>`);
}

/**
 * Убирает тег запрещающий перевод
 * @param {string} str
 * @returns {str}
 */
export function unspanText(str) {
  return str?.replace(/(<span translate="no">)|(<\/span>)/gi, ``);
}

/**
 * Отфильтровывает ноды с числами, пустые строки и исключения
 * @param {Node} node
 */
function nodeFilter(node) {
  const text = node.textContent.trim();
  if (!text.length) return NodeFilter.FILTER_SKIP;

  const isNaN = Number.isNaN(+node.textContent);

  if (isNaN) {
    if (excludeTexts.includes(node.textContent)) {
      return NodeFilter.FILTER_SKIP;
    }

    return NodeFilter.FILTER_ACCEPT;
  } else {
    return NodeFilter.FILTER_SKIP;
  }
}
