export default {
  namespaced: true,
  state: {
    screenSize: null,
  },
  mutations: {
    setScreenSize(state, size) {
      state.screenSize = size;
    },
  },
};
