<template>
  <ModalComp class="l-modal">
    <template #default>
      <div class="l-modal__body">
        <div class="l-modal__head">
          <h2 class="l-modal__title">{{ getText }}</h2>
          <button @click="closeModal()" class="l-modal__close">
            <IconClose />
          </button>
        </div>
        <div class="l-modal__items">
          <button
            v-for="item of languages"
            :key="item.code"
            class="l-modal__item"
            type="button"
            @click="
              setLanguage(item.code);
              setQuery({ lang: item.code });
              closeModal();
            "
          >
            <div class="l-modal__item-icon">
              <img
                :src="staticUrl(`static/flags/${item.code}.svg`)"
                :alt="item.code"
                width="54"
                height="40"
              />
            </div>
            <div class="l-modal__item-name">{{ item.name }}</div>
          </button>
        </div>
      </div>
    </template>
  </ModalComp>
</template>

<script setup>
import IconClose from "@/components/icons/IconClose.vue";
import ModalComp from "@/components/modals/ModalComp.vue";
import useLanguage from "@/hooks/useLanguage";
import useLanguageModal from "@/hooks/useLanguageModal";
import useQuery from "@/hooks/useQuery";
import { staticUrl } from "@/utils";
import { computed } from "vue";

const { closeModal } = useLanguageModal();
const { getLanguage, setLanguage, languages } = useLanguage();
const { setQuery } = useQuery();

const getText = computed(() => {
  const lang = getLanguage();
  if (lang === "ky") return "Тилди тандаңыз";
  if (lang === "tg") return "Забонро интихоб кунед";
  if (lang === "uz") return "Tilni tanlang";
  return "Выберите язык";
});
</script>

<style lang="scss">
.l-modal {
  // .l-modal__body
  &__body {
    display: grid;
    gap: var(--widthGapH);
  }
  // .l-modal__head
  &__head {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;
  }
  // .l-modal__close
  &__close {
    @include reset-button;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
  }
  // .l-modal__items
  &__items {
    display: grid;
    gap: var(--widthGapV);
    @include md {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  // .l-modal__item
  &__item {
    display: flex;
    align-items: center;
    height: 48px;
    padding: 6px;
    border: none;
    border-radius: var(--radiusInner);
    background: var(--colorGrey);
    cursor: pointer;
    @include md {
      border-radius: 12px;
    }
  }
  // .l-modal__item-icon
  &__item-icon {
    img {
      display: block;
      border-radius: 8px;
    }
  }
  // .l-modal__item-name
  &__item-name {
    font-size: var(--sizeHeading2);
    font-weight: 800;
    font-family: var(--fontSecondary);
    line-height: var(--lineHeightHeading2);
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    color: var(--colorBlack);
  }
}
</style>
