export default {
  namespaced: true,
  state: {
    sub: {},
  },
  mutations: {
    setSub(state, value) {
      state.sub = value;
    },
  },
};
