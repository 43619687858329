import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      screenSize: (state) => state.device.screenSize,
    }),
    breakpointsMobile() {
      return ["xs", "sm", "md"];
    },
    breakpointsDesktop() {
      return ["lg", "xl", "xxl"];
    },
  },
};
