<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
    <path d="M5.98866 12.8191H0V18.8437H5.98866V12.8191Z" fill="#FF3495" />
    <path
      d="M13.5871 28.9473V25.6476C13.5871 25.6406 13.5871 25.6337 13.5871 25.6268V18.8436H19.5758V12.8191H13.5871V5.99994H7.5625V25.6268C7.5625 30.7781 11.7535 34.9691 16.9048 34.9691H21.8433V28.9473H13.5885H13.5871Z"
      fill="white"
    />
    <path d="M39.5289 28.9456H23.4146V34.9702H39.5289V28.9456Z" fill="white" />
    <path
      d="M39.9988 18.842C39.9988 13.6493 35.7747 9.42517 30.5819 9.42517C25.3892 9.42517 21.165 13.6493 21.165 18.842H27.1896C27.1896 16.9711 28.7124 15.4484 30.5833 15.4484C32.4542 15.4484 33.977 16.9711 33.977 18.842C33.977 20.713 32.4542 22.2357 30.5833 22.2357H23.4174V28.2603H28.1831L31.0863 28.2465C36.0455 27.9839 40.0002 23.8676 40.0002 18.8434L39.9988 18.842Z"
      fill="white"
    />
  </svg>
</template>
